import { Config } from "./config";
import axios from "axios";

const SESSION_STORAGE_KEY_AUTH_USER_NAME = "auth-user-name";
const SESSION_STORAGE_KEY_AUTH_BEARER = "auth-bearer";
const SESSION_STORAGE_EMBEDDED_PARAMETER = "auth-embedded-param";

export const login = async (name, pass) => {
  const data = await axios.post(Config.ext.login + "/login", {
    info: btoa(`${name}:${pass}`),
  });

  if (data.data.status == 200) {
    const user = data.data.user;
    persist(SESSION_STORAGE_KEY_AUTH_USER_NAME, user);

    try {
      const token = await refreshToken(user);

      persist(SESSION_STORAGE_KEY_AUTH_BEARER, token.data.access_token);
    } catch (e) {
      console.error("No token obtainedW");
    }

    return data.data;
  } else {
    console.error("Not right login");
    throw "Wrong credentials";
  }
};

/*
export const loginTest = async () => {

    const user = 'bVpNSlFrRGFIdWtzZWlkOU9VMUhmcnlmZXlNYTpMVlBPQ2NOQV83ZnZhQnYxcm9scUh1MGJIX1Fh';

    var dataTest = {
      "status": "200",
      "user": user,
      "identification": "lux"
    }


    persist(SESSION_STORAGE_KEY_AUTH_USER_NAME, user);
    console.log(user)
    try {
      const token = await refreshToken(user);

      persist(SESSION_STORAGE_KEY_AUTH_BEARER, token.data.access_token);
    } catch (e) {
      console.error("No token obtainedW");
    }

    return dataTest;
 
};*/

export const isLoggedIn = async () => {
  if (!persistCheck(SESSION_STORAGE_KEY_AUTH_USER_NAME)) return false;

  const user = persistGet(SESSION_STORAGE_KEY_AUTH_USER_NAME);

  try {
    const oauth = await refreshToken(user);
    persist(SESSION_STORAGE_KEY_AUTH_BEARER, oauth.access_token);

    return true;
  } catch (error) {
    return false; // TODO: Kora add to false;
  }
};

/**
 * returns true if login Embed parameter is set is sessionStorage
 * @returns boolean parameter
 */
export const hasLoginEmbed = async (routeParam) => {

if(isPortalRouteParam(routeParam)){
  console.log(
    "embedded parameter:" + persistCheck(SESSION_STORAGE_EMBEDDED_PARAMETER)
  );
  if (persistCheck(SESSION_STORAGE_EMBEDDED_PARAMETER)) return true;
  return false;
}

return false;

};

/**
 * checks if route parameter require login embedd parameter for GUI changes 
 * @param {string} routeParam
 * @returns bool 
 */

const isPortalRouteParam = (routeParam) => {
  switch(routeParam){
    case '/eierbytte': return true;
    case '/sla': return true;
    default: return false;
  }

}
/**
 * checks if URL provides query parameter
 * @param {string} queryParam
 * @returns bool
 */
export const hasQueryParam = async (queryParam) => {
  if (Object.keys(queryParam).length === 0) {
    return false;
  }
  return true;
};

export const clearEmbedParam = async () => {
  //if any EMBEDDED_PARAMETER is saved in sessionStorage, the storage is cleared, as there are no parameters.
  if (persistCheck(SESSION_STORAGE_EMBEDDED_PARAMETER)) {
    persistDelete(SESSION_STORAGE_EMBEDDED_PARAMETER);
    persistDelete(SESSION_STORAGE_KEY_AUTH_USER_NAME);
    persistDelete(SESSION_STORAGE_KEY_AUTH_BEARER);
  }
};

/**
 * parse query parameters and save to sessionStorage
 * SESSION_STORAGE_KEY_AUTH_USER_NAME required for api mgmt user authentication
 * SESSION_STORAGE_EMBEDDED_PARAMETER required for GUI changes
 * @param {string} queryParam
 * @returns
 */
export const parseParam = async (queryParam) => {
  try {
    persist(SESSION_STORAGE_KEY_AUTH_USER_NAME, Config.ext.embeddedParamAuthToken);
    persist(SESSION_STORAGE_EMBEDDED_PARAMETER, queryParam.embededinfo);
  } catch (error) {
    return error;
  }
};

export const refreshToken = async (user) => {
  const data = await axios.post(
    Config.ext.refreshTokenUrl,
    "grant_type=client_credentials",
    {
      headers: {
        Authorization: `Basic ${user}`,
      },
    }
  );

  return data.data;
};

export const logout = () => {
  persistClear();
};

export const token = () => {
  return persistGet(SESSION_STORAGE_KEY_AUTH_BEARER);
};

export const setFaultFormPayload = () => {
  if(persistCheck(SESSION_STORAGE_EMBEDDED_PARAMETER)){
    return embeddedEmailBase64();
  }

  return userBase64();

}

export const embeddedEmailBase64 = () => persistGet(SESSION_STORAGE_EMBEDDED_PARAMETER);

export const userBase64 = () => persistGet(SESSION_STORAGE_KEY_AUTH_USER_NAME);

const persist = (key, value) => sessionStorage.setItem(key, value);
const persistGet = (key) => sessionStorage.getItem(key);
const persistClear = () => sessionStorage.clear();
const persistCheck = (key) => sessionStorage.getItem(key) !== null;
const persistDelete = (key) => sessionStorage.removeItem(key);
