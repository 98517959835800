import i18n from '../i18n'

export const MELDING_STATUS = {
    NORMAL_DRIFT: 'Normal drift', //1
    REGISTERED: 'Avvik innrapportert', //2
    JOB_CREATED_NOT_DELEGATED: 'Jobb opprettet, ikke delegert', //3
    JOB_ONGOING: 'Jobb pågår', //4
    JOB_ON_HOLD: 'Jobb på vent', //5
    JOB_DONE: 'Jobb ferdig. Normal drift', //6 
    JOB_DOCUMENTED_COMPLETED: 'Jobb dokumentert og avsluttet', //7,
    JOB_ASSIGNED: 'Jobb tildelt', //8,
    PLANNED: 'Planlagt', //9
    CLOSED: 'Lukket', // old one, not used, only visible in arcgis online
}


export function getPopupStatus(meldingStatus) {
    console.log('ooo', meldingStatus);
    if (meldingStatus === '' || meldingStatus === null) {
        return i18n.t('status.name.normal');
    }

    let popupStatus = {
        [MELDING_STATUS.NORMAL_DRIFT]: i18n.t('status.name.normal'),
        [MELDING_STATUS.JOB_DONE]: i18n.t('status.name.normal'),
        [MELDING_STATUS.JOB_DOCUMENTED_COMPLETED]: i18n.t('status.name.normal'),
        [MELDING_STATUS.REGISTERED]: i18n.t('status.name.registered'),
        [MELDING_STATUS.JOB_CREATED_NOT_DELEGATED]: i18n.t('status.name.confirmed'),
        [MELDING_STATUS.JOB_ON_HOLD]: i18n.t('status.name.inProgress'),
        [MELDING_STATUS.JOB_ASSIGNED]: i18n.t('status.name.inProgress'),
        [MELDING_STATUS.JOB_ONGOING]: i18n.t('status.name.inProgress'),
        [MELDING_STATUS.PLANNED]: i18n.t('status.name.planned'),
    };

    return !popupStatus[meldingStatus] ? 'UNKNOWN STATUS (' + meldingStatus + ')' : popupStatus[meldingStatus];
}


// {
//     "name": "Normal drift",
//     "code": "Normal drift"
//    },
//    {
//     "name": "Avvik innrapportert",
//     "code": "Avvik innrapportert"
//    },
//    {
//     "name": "Jobb opprettet, ikke delegert",
//     "code": "Jobb opprettet, ikke delegert"
//    },
//    {
//     "name": "Jobb pågår",
//     "code": "Jobb pågår"
//    },
//    {
//     "name": "Jobb på vent",
//     "code": "Jobb på vent"
//    },
//    {
//     "name": "Jobb ferdig. Normal drift",
//     "code": "Jobb ferdig. Normal drift"
//    },
//    {
//     "name": "Jobb dokumentert og avsluttet",
//     "code": "Jobb dokumentert og avsluttet"