<template>
    <div>
        <b-tooltip :label="$t('nav.language')" position="is-bottom" type="is-light">
            <b-field>
                <b-radio-button v-model="language" native-value="no">
                    <span>{{ $t('nav.no') }}</span>
                </b-radio-button>

                <b-radio-button v-model="language" native-value="en">
                    <span>{{ $t('nav.en') }}</span>
                </b-radio-button>
            </b-field>
        </b-tooltip>
    </div>
</template>

<script>
import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
    name: 'LanguageSelector',
    props: {
        message: String
    },
    data: () => {
        return {
            language: 'no'
        };
    },
    watch: {
        language: newVal => {
            i18n.locale = newVal;
        }
    }
});
</script>
