var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fault-form"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGatelysUser),expression:"isGatelysUser"}]},[_c('h4',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.$t("faultForm.reportFault")))]),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.address),expression:"form.address"}],staticClass:"subtitle is-4"},[_vm._v(" "+_vm._s(_vm.form.address)+" ")]),_c('b-field',{attrs:{"type":_vm.form.faultSelected !== null && _vm.form.faultSelected !== ''
              ? 'is-success'
              : null}},[_c('template',{slot:"label"},[_vm._v(_vm._s(_vm.$t("faultForm.label.faultType")))]),_c('b-select',{attrs:{"placeholder":_vm.$t('faultForm.placeholder.faultType'),"required":""},model:{value:(_vm.form.faultSelected),callback:function ($$v) {_vm.$set(_vm.form, "faultSelected", $$v)},expression:"form.faultSelected"}},_vm._l((_vm.faultOptions),function(option){return _c('option',{key:option.code,attrs:{"title":option.name},domProps:{"value":option.name}},[_vm._v(" "+_vm._s(_vm.$t(_vm.translateFaultType(option.code)))+" ")])}),0)],2),_c('b-field',{attrs:{"label":_vm.$t('faultForm.label.phoneNumber') + ' ' + _vm.requiredOrOptional,"message":(_vm.phoneRequired && _vm.form.phone === '') ||
            (_vm.form.phone !== '' && !_vm.validPhoneNumber)
              ? _vm.$t('faultForm.validation.phone')
              : '',"type":{
            'is-success': _vm.form.phone !== '' && _vm.validPhoneNumber,
            'is-danger':
              (_vm.phoneRequired && _vm.form.phone === '') ||
              (_vm.form.phone !== '' && !_vm.validPhoneNumber),
            '': _vm.form.phone === '',
          }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('faultForm.placeholder.phoneNumber'),"icon-pack":"fas","icon":"phone-square"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('b-field',{attrs:{"label":_vm.$t('faultForm.label.description'),"message":_vm.form.faultSelected && _vm.form.description === '' ? _vm.$t('faultForm.validation.description') : '',"type":{
              'is-success': _vm.form.description,
              'is-danger': _vm.form.faultSelected !== null && !_vm.form.description

          }}},[_c('b-input',{attrs:{"maxlength":"100","type":"textarea","rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),(_vm.form.file)?_c('div',{staticClass:"help is-danger"},[(_vm.form.file && _vm.form.file.size > _vm.maxFileSizeBytes)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("faultForm.validation.fileSize", { size: _vm.maxFileSizeBytes / 1000000, }))+" ")]):_vm._e(),(
              _vm.form.file &&
              !['image/jpeg', 'image/png', 'video/mp4'].includes(
                _vm.form.file.type
              )
            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("faultForm.validation.fileType"))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"selected-file"},[(_vm.form.file)?_c('span',{staticClass:"file-name-custom"},[_vm._v(_vm._s(_vm.form.file.name))]):_vm._e(),_c('span',{staticStyle:{"display":"inline"}},[(_vm.form.file)?_c('button',{staticClass:"delete-file",on:{"click":_vm.deleteFile}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1):_vm._e()])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isServiceUser),expression:"isServiceUser"}]},[_c('b-field',{attrs:{"label":_vm.$t('ownerChangeForm.label.newOwner')}},[_c('b-select',{attrs:{"placeholder":""},model:{value:(_vm.form.owner),callback:function ($$v) {_vm.$set(_vm.form, "owner", $$v)},expression:"form.owner"}},_vm._l((_vm.owners()),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.title)+" ")])}),0)],1),_c('b-field',{attrs:{"label":_vm.$t('faultForm.label.description'),"message":_vm.form.description === ''
              ? _vm.$t('faultForm.validation.description')
              : '',"type":{
            'is-danger': _vm.form.description === '',
            'is-success': _vm.form.description !== '',
          }}},[_c('b-input',{attrs:{"maxlength":"100","type":"textarea","rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSlaUser),expression:"isSlaUser"}]},[_c('b-field',{attrs:{"label":_vm.$t('slaChangeForm.label.newSla')}},[_c('b-select',{attrs:{"placeholder":""},model:{value:(_vm.form.sla),callback:function ($$v) {_vm.$set(_vm.form, "sla", $$v)},expression:"form.sla"}},_vm._l((_vm.slas()),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.title + " -- " + option.description)+" ")])}),0)],1),_c('b-field',{attrs:{"label":_vm.$t('faultForm.label.description'),"message":_vm.form.description === ''
              ? _vm.$t('faultForm.validation.description')
              : '',"type":{
            'is-danger': _vm.form.description === '',
            'is-success': _vm.form.description !== '',
          }}},[_c('b-input',{attrs:{"maxlength":"100","type":"textarea","rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGatelysUser),expression:"isGatelysUser"}],attrs:{"type":"is-light","disabled":!this.sendEnabled,"expanded":""},on:{"click":_vm.send}},[_c('font-awesome-icon',{attrs:{"icon":"check"}}),_vm._v(" "+_vm._s(_vm.$t("faultForm.sendButton")))],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isServiceUser),expression:"isServiceUser"}],attrs:{"type":"is-light","disabled":!this.sendOwnerEnabled,"icon-left":"check","expanded":""},on:{"click":_vm.sendOwnerChange}},[_vm._v(" "+_vm._s(_vm.$t("ownerChangeForm.changeOwnerButton")))]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSlaUser),expression:"isSlaUser"}],attrs:{"type":"is-light","disabled":!this.sendSlaEnabled,"icon-left":"check","expanded":""},on:{"click":_vm.sendSlaChange}},[_vm._v(" "+_vm._s(_vm.$t("slaChangeForm.slaChangeButton")))])],1),_c('div',{staticClass:"column is-6"},[_c('b-button',{attrs:{"type":"is-light","expanded":""},on:{"click":_vm.closeForm}},[_c('font-awesome-icon',{attrs:{"icon":"times"}}),_vm._v(" "+_vm._s(_vm.$t("faultForm.cancelButton"))+" ")],1)],1)]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('a',{attrs:{"href":"https://www.lyse.no/personvern","target":"_blank"}},[_vm._v("Personvern")])])])}]

export { render, staticRenderFns }