<template>
    <section class="section">
        <div class="content report-fault is-size-6">
            <h4 class="title is-4">{{ $t('reportFault.title') }}</h4>
            <p v-html="$t('reportFault.textHeader')" />
            <ul class="report-types">
                <li v-html="$t('reportFault.danger')" />
                <li v-html="$t('reportFault.areaDarkened')" />
                <li v-html="$t('reportFault.spotNotLightProperly')" />
                <li v-html="$t('reportFault.other')" />
            </ul>
            <p v-html="$t('reportFault.textFooter')" />
        </div>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'ReportFault'
});
</script>

<style scoped>
.report-types {
    margin-left: 1em;
}
</style>