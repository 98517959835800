import Vue from "vue";
import VueRouter from "vue-router";
import LoginForm from "../components/LoginForm.vue";
import App from "../App.vue";
import {
  hasQueryParam,
  isLoggedIn,
  parseParam,
  clearEmbedParam,
} from "../auth.js";
import { Config } from "@/config";

const init = () => {
  console.log(Config.ext.subfolder);

  Vue.use(VueRouter);

  const routes = [
    { path: "", component: App },
    {
      path: "/eierbytte",
      component: App,
      beforeEnter: async (to, from, next) => {
        console.log("received query in eierbytte");

        if(to.path ==="/eierbytte"){
        const hasUrlEmbParam = await hasQueryParam(to.query);

        if (hasUrlEmbParam) {
          await parseParam(to.query);
        } else {
          await clearEmbedParam();
        }}

        var isLogged = await isLoggedIn();

        isLogged ? next() : next({name: 'login', params: {armaturPortalName: to.path}});
      },
    },
    {
      path: "/sla",
      component: App, 
      beforeEnter: async (to, from, next) => {
        console.log("received query in sla");

        if(to.path ==="/sla"){
          const hasUrlEmbParam = await hasQueryParam(to.query);
  
          if (hasUrlEmbParam) {
            await parseParam(to.query);
          } else {
            await clearEmbedParam();
          }}

        var isLogged = await isLoggedIn();
        isLogged ? next() : next({name: 'login', params: {armaturPortalName: to.path}});
      },
    },
    {name:'login', path: "/login", component: LoginForm, props: true },
  ];
  const router = new VueRouter({
    mode: "history",
    routes,
    base: Config.ext.subfolder,
  });

  return router;
};

export default init;
