
export const SEVERITY = {
    EMERGENCY: 'Emergency', //1
    URGENT_ERROR: 'Urgent Error', //2
    ERROR: 'Error', //3
    CONFIGURATION_ERROR: 'Configuration error', //4
    WARNING: 'Warning', //5
    INFO: 'Info', //6 
}

// "name": "SEVERITY",
// "type": "esriFieldTypeString",
// "alias": "SEVERITY",
// "domain": {
//  "type": "codedValue",
//  "name": "Lyse_GatelysMeldingSeverity",
//  "description": "",
//  "codedValues": [
//   {
//    "name": "Emergency",
//    "code": "Emergency"
//   },
//   {
//    "name": "Urgent Error",
//    "code": "Urgent Error"
//   },
//   {
//    "name": "Error",
//    "code": "Error"
//   },
//   {
//    "name": "Configuration error",
//    "code": "Configuration error"
//   },
//   {
//    "name": "Warning",
//    "code": "Warning"
//   },
//   {
//    "name": "Info",
//    "code": "Info"
//   }