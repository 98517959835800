<template>
    <b-message type="is-warning" has-icon>{{ $t('geolocation.error') }} {{ message }}</b-message>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'GeolocationDisabled',
    props: {
        message: String
    }
});
</script>
