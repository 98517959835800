<template>
  <div class="fault-form">
    <section class="section">
      <div class="container">

        <!-- GATELYS CHANGE FORM-->
        <div v-show="isGatelysUser">
          <h4 class="title is-4">{{ $t("faultForm.reportFault") }}</h4>
          <h4 class="subtitle is-4" v-show="form.address">
            {{ form.address }}
          </h4>

          <b-field
            :type="
              form.faultSelected !== null && form.faultSelected !== ''
                ? 'is-success'
                : null
            "
          >
            <template slot="label">{{
              $t("faultForm.label.faultType")
            }}</template>
            <b-select
              :placeholder="$t('faultForm.placeholder.faultType')"
              v-model="form.faultSelected"
              required
            >
              <option
                v-for="option in faultOptions"
                :value="option.name"
                :key="option.code"
                :title="option.name"
              >
                {{ $t(translateFaultType(option.code)) }}
              </option>
            </b-select>
          </b-field>

          <b-field
            :label="
              $t('faultForm.label.phoneNumber') + ' ' + requiredOrOptional
            "
            :message="
              (phoneRequired && form.phone === '') ||
              (form.phone !== '' && !validPhoneNumber)
                ? $t('faultForm.validation.phone')
                : ''
            "
            :type="{
              'is-success': form.phone !== '' && validPhoneNumber,
              'is-danger':
                (phoneRequired && form.phone === '') ||
                (form.phone !== '' && !validPhoneNumber),
              '': form.phone === '',
            }"
          >
            <b-input
              v-model="form.phone"
              :placeholder="$t('faultForm.placeholder.phoneNumber')"
              icon-pack="fas"
              icon="phone-square"
            ></b-input>
          </b-field>
          
          <b-field
            :label="
              $t('faultForm.label.description')
            "
            :message="form.faultSelected && form.description === '' ? $t('faultForm.validation.description') : ''"
            :type="{
                'is-success': form.description,
                'is-danger': form.faultSelected !== null && !form.description

            }"
          >
            <b-input
              maxlength="100"
              type="textarea"
              rows="3"
              v-model="form.description"
            ></b-input>
          </b-field>

          <div class="help is-danger" v-if="form.file">
            <div v-if="form.file && form.file.size > maxFileSizeBytes">
              {{
                $t("faultForm.validation.fileSize", {
                  size: maxFileSizeBytes / 1000000,
                })
              }}
            </div>
            <div
              v-if="
                form.file &&
                !['image/jpeg', 'image/png', 'video/mp4'].includes(
                  form.file.type
                )
              "
            >
              {{ $t("faultForm.validation.fileType") }}
            </div>
          </div>
          <div class="selected-file">
            <span class="file-name-custom" v-if="form.file">{{
              form.file.name
            }}</span>
            <span style="display: inline">
              <button
                v-if="form.file"
                v-on:click="deleteFile"
                class="delete-file"
              >
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </button>
            </span>
          </div>
        </div>
        <!--GATELYS CHANGE FORM-->

        <!-- CHANGE OWNER FORM -->
        <div v-show="isServiceUser">
          
          <b-field :label="$t('ownerChangeForm.label.newOwner')">
            <b-select placeholder="" v-model="form.owner">
                <option
                    v-for="option in owners()"
                    :value="option.id"
                    :key="option.id">
                    {{ option.title }}
                </option>
            </b-select>
          </b-field>

          <b-field
            :label="$t('faultForm.label.description')"
            :message="
              form.description === ''
                ? $t('faultForm.validation.description')
                : ''
            "
            :type="{
              'is-danger': form.description === '',
              'is-success': form.description !== '',
            }"
          >
            <b-input
              maxlength="100"
              type="textarea"
              rows="3"
              v-model="form.description"
            ></b-input>
          </b-field>
        </div>

        <!-- END OWNER FORM -->

        <!-- CHANGE SLA FORM  --> 
        <div v-show="isSlaUser">
          
          <b-field :label="$t('slaChangeForm.label.newSla')">
            <b-select placeholder="" v-model="form.sla">
                <option
                    v-for="option in slas()"
                    :value="option.id"
                    :key="option.id">
                    {{ option.title + " -- " + option.description}}
                </option>
            </b-select>
          </b-field>

          <b-field
            :label="$t('faultForm.label.description')"
            :message="
              form.description === ''
                ? $t('faultForm.validation.description')
                : ''
            "
            :type="{
              'is-danger': form.description === '',
              'is-success': form.description !== '',
            }"
          >
            <b-input
              maxlength="100"
              type="textarea"
              rows="3"
              v-model="form.description"
            ></b-input>
          </b-field>
        </div>
        <!--END OF CHANGE SLA FORM-->


        <div class="columns">
          <div class="column is-6">
            <!--GATELYS BUTTONS-->
            <b-button
              v-show="isGatelysUser"
              v-on:click="send"
              type="is-light"
              :disabled="!this.sendEnabled"
              expanded
              ><font-awesome-icon icon="check"></font-awesome-icon>
              {{ $t("faultForm.sendButton") }}</b-button
            >
            
            <!--CHANGE OWNER BUTTON-->
            <b-button
              v-show="isServiceUser"
              v-on:click="sendOwnerChange"
              type="is-light"
              :disabled="!this.sendOwnerEnabled"
              icon-left="check"
              expanded>
              {{ $t("ownerChangeForm.changeOwnerButton") }}</b-button>
            
            <!--CHANGE SLA BUTTON-->  
              <b-button
              v-show="isSlaUser"
              v-on:click="sendSlaChange"
              type="is-light"
              :disabled="!this.sendSlaEnabled"
              icon-left="check"
              expanded>
              {{ $t("slaChangeForm.slaChangeButton") }}</b-button>  
          </div>
          <div class="column is-6">
            <b-button v-on:click="closeForm" type="is-light" expanded>
              <font-awesome-icon icon="times"></font-awesome-icon>
              {{ $t("faultForm.cancelButton") }}
            </b-button>
          </div>
        </div>

        <div class="columns">
          <div class="column is-12">
            <a href="https://www.lyse.no/personvern" target="_blank"
              >Personvern</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { getDomainValues, mobileCheck, isServiceUser, isSlaUser, isGatelysUser } from "../utils";

import { Config } from "@/config";
import axios from "axios";

import MapView from "@arcgis/core/views/MapView";
import { eventBus } from "../event-bus";
import { MELDING_STATUS } from "../enums/melding-status";
import { MELDINGSTYPE, MELDINGSTYPE_TRANS } from "../enums/melding-type";
import { SEVERITY } from "../enums/severity";
import { v4 as uuidv4 } from "uuid";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUpload,
  faExclamationCircle,
  faTimes,
  faCheck,
  faTrashAlt,
  faUser
} from "@fortawesome/free-solid-svg-icons";

import {token, setFaultFormPayload} from '../auth.js';
import _ from 'lodash';

library.add(faUpload, faExclamationCircle, faTimes, faCheck, faTrashAlt, faUser);

export default Vue.extend({
  name: "FaultForm",
  props: {
    view: {
      type: MapView,
      required: true,
    },
  },
  data: function () {
    return {
      form: {
        x: null, // this.x,
        y: null, // this.y,
        gisId: null, //this.gisId,
        address: null,
        phone: "",
        email: "",
        description: "",
        faultSelected: null,
        file: null,
        newOwner: "", // improper variable? - owner form field already exists 
        objectId: null,
        ean: null,
        owner: "",
        sla: ""
      },
      currentOwner: '',
      currentSLA: '',
      faultOptions: [],
      maxFileSizeBytes: Config.ext.maxFileSizeMb * 1000000
    };
  },
  computed: {
    validPhoneNumber: function () {
      return (
        this.form.phone.match(/\d/g) !== null &&
        (this.form.phone.match(/\d/g).length === 8 ||
          this.form.phone.match(/\d/g).length === 12)
      );
    },
    phoneRequired: function () {
      return (
        this.form.faultSelected === MELDINGSTYPE.OTHER ||
        this.form.faultSelected === MELDINGSTYPE.DANGER ||
        this.form.faultSelected === MELDINGSTYPE.AREA_DARKENED
      );
    },

    validDescription: function () {
      return this.descriptionRequired ? this.form.description !== "" : true;
    },
    descriptionRequired: function () {
      return true;
    },
    validFile: function () {
      let fileTypesAllowed = ["image/jpeg", "image/png", "video/mp4"];
      let fileValid =
        this.form.file !== undefined &&
        this.form.file !== null &&
        this.form.file.size < this.maxFileSizeBytes &&
        fileTypesAllowed.includes(this.form.file.type);
      return fileValid;
    },
    phoneMessageVisiblity: function () {
      if (this.form.phone && !this.validPhoneNumber) {
        return true;
      }

      if (this.form.faultSelected === MELDINGSTYPE.OTHER) {
        if (!this.validPhoneNumber) return true;
        else return false;
      }
      return false;
    },
    emailMessageVisibility: function () {
      if (this.form.email !== "") {
        return true;
      }
      if (this.form.faultSelected === MELDINGSTYPE.OTHER) {
        if (!this.validPhoneNumber) return true;
        else return false;
      }
      return false;
    },
    sendEnabled: function () {
      let visible =
        this.form.faultSelected !== null && this.form.faultSelected !== "";
     
      if (this.phoneRequired) {
        visible = visible && this.form.phone && this.validPhoneNumber;
      }

      if (this.form.phone) {
        visible = visible && this.validPhoneNumber;
      }

      return visible && 
                this.form.description &&
                this.validDescription;
      
    },
    requiredOrOptional: function () {
      return this.form.faultSelected === MELDINGSTYPE.OTHER
        ? this.$t("faultForm.required")
        : this.$t("faultForm.optional");
    },
    validOwnerToken: function () {
      return true;
    },

    sendOwnerEnabled: function () {
      return (
        this.form.description !== "" && this.form.owner !== ""
      );
    },

    sendSlaEnabled: function (){
      return (
        this.form.description !== "" && this.form.sla !== ""
      );
    },
    isServiceUser: function () {
      return isServiceUser();
    },

    isSlaUser: function () {
      return isSlaUser();
    }, 

    isGatelysUser: function() {
      return isGatelysUser();
    }
  },

  methods: {
    send: function () {
      let source = mobileCheck() ? "MOBILE" : "WEB";

      

      const payload = {
        attributtes: {
          lampId: this.form.globalId,
          gisId: this.form.gisId,
          state: this.form.faultSelected,
          phone: this.form.phone,
          description: this.form.description,
          status: MELDING_STATUS.REGISTERED,
          severity: this.setSeverity(this.form.faultSelected),
          globalId: `{${uuidv4()}}`,
          created: new Date().valueOf(),
          source: source,
        },
        geometry:{
          spatialReference: { wkid: 25833 },
          x: this.form.x,
          y: this.form.y
        }
      };

      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      axios({
        method: "post",
        url: Config.ext.fieldService,
        data: payload
      })
            // .post(Config.ext.fieldService, payload)
            .then(() => {
              this.resetForm();
              eventBus.$emit("faultFormChanged", null);
              this.toast("is-success", this.$t("faultForm.sendSuccess"));
            })
            .catch((error) => {
              this.toast("is-danger", this.$t("faultForm.sendError"), 5000);
              console.error("Error sending attachment: ", error);
            } )
            .finally(() => {
              loadingComponent.close();
            });
    },
    sendOwnerChange: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      const payload = {
          id: uuidv4(),
          TEKNISKPLASS_GISID: this.form.gisId,
          STARTTIDSPUNKT: new Date().valueOf(),
          KOMMENTAR: this.form.description,
          OBJECTID: this.form.objectId,
          NEW_OWNER: this.form.owner,
          CURRENT_OWNER: this.currentOwner,
          EAN_ID: this.form.ean,
          REQUESTER: setFaultFormPayload()
      };

      axios({
        method: "post",
        url: Config.ext.ownerChangeUrl,
        data: payload,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
        .then(() => {
          this.toast("is-success", this.$t("ownerChangeForm.sendSuccess"));
          eventBus.$emit("faultFormChanged", null);
          this.resetForm();
        })
        .catch((error) => {
          console.log('error :>> ', error);
          this.toast("is-danger", this.$t("ownerChangeForm.sendFail", 5000));
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
    sendSlaChange: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null,
      });

      const payload = {
          id: uuidv4(),
          TEKNISKPLASS_GISID: this.form.gisId,
          STARTTIDSPUNKT: new Date().valueOf(),
          KOMMENTAR: this.form.description,
          OBJECTID: this.form.objectId,
          NEW_SLA: this.form.sla,
          CURRENT_SLA: this.currentSLA,
          EAN_ID: this.form.ean,
          REQUESTER: setFaultFormPayload()
      };
      //TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      axios({
        method: "post",
        url: Config.ext.slaChangeUrl,
        data: payload,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
        .then(() => {
          this.toast("is-success", this.$t("slaChangeForm.sendSuccess"));
          eventBus.$emit("faultFormChanged", null);
          this.resetForm();
        })
        .catch((error) => {
          console.log('error :>> ', error);
          this.toast("is-danger", this.$t("slaChangeForm.sendFail", 5000));
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
    deleteFile: function () {
      this.form.file = null;
      this.$refs["input-file"].value = "";
    },
    resetForm: function () {
      this.form.x = null;
      this.form.y = null;
      this.form.gisId = null;
      this.form.faultSelected = null;
      this.form.phone = "";
      this.form.address = null;
      this.form.description = "";
      this.form.file = null;
      this.form.newOwner = ""; // should add owner instead? 
      this.form.objectId = null;
      this.form.globalId = null;
      this.form.ean = null;
      this.form.sla = "";
    },
    closeForm: function () {
      eventBus.$emit("faultFormChanged", null);
    },
    setSeverity: function (faultType) {
      let severities = {
        [MELDINGSTYPE.DANGER]: SEVERITY.EMERGENCY,
        [MELDINGSTYPE.AREA_DARKENED]: SEVERITY.URGENT_ERROR,
        [MELDINGSTYPE.ONE_SPOT_NOT_BRIGHTNING]: SEVERITY.ERROR,
        [MELDINGSTYPE.OTHER]: SEVERITY.URGENT_ERROR,
      };
      return !severities[faultType] ? SEVERITY.ERROR : severities[faultType];
    },
    //TODO add to some utils. Now here because toast from beaufy is not accessbile outside Vue app
    toast: function (type, message, duration) {
      this.$buefy.toast.open({
        duration: duration || 5000,
        message: message,
        position: "is-bottom",
        type: type,
      });
    },
    fileChanged: function () {
      this.form.file = this.$refs["input-file"].files[0];
    },
    translateFaultType: function (domainCode) {
      return MELDINGSTYPE_TRANS.get(domainCode);
    },
    owners: function() {
      if (!this.isServiceUser) { return [] }

      let r = this.$parent.map.layers.items.filter(m =>  m.id === "owners-layer")[0].renderer;

      if (r && r.uniqueValueInfos) {
        const o = r.uniqueValueInfos.map((item) => ({
                        title: item.label,
                        id: item.label
                    }));

        const uniq = _.uniqBy(o, 'title');

        return uniq;

      } else {
        return []
      }
    },

    slas: function() {
      if (!this.isSlaUser) { return [] }

      let r = this.$parent.map.layers.items.filter(m =>  m.id === "slas-layer")[0].renderer;

      if (r && r.uniqueValueInfos) {
        const o = r.uniqueValueInfos.map((item) => ({
                        description: item.label,
                        title: item.value,
                        id: item.value
                    }));

        const uniq = _.uniqBy(o, 'title');

        return uniq;

      } else {
        return []
      }
    },
  },

  created: function () {
    eventBus.$on("report-fault-data", (selected) => {
      eventBus.$emit("faultFormChanged", selected);
      this.form.x = selected.x;
      this.form.y = selected.y;
      this.form.gisId = selected.gisId;
      this.form.address = selected.address;
      this.form.objectId = selected.objectId;
      this.form.globalId = selected.globalId;
      this.form.ean = selected.ean;
      this.currentSLA = selected.sla;
      this.currentOwner = selected.currentOwner
    });
    

    eventBus.$on("popupVisible", (visible) => {
      if (!visible) {
        this.notificationLayer.refresh();
        eventBus.$emit("faultFormChanged", null);
        this.resetForm();
      }
    });

    this.view.when(() => {
      this.notificationLayer =
        this.view.map.findLayerById("notification-layer");
      this.notificationLayer.when().then(() => {
        let faultTypeDomains = getDomainValues(
          this.notificationLayer,
          Config.MELDINGSTYPE
        );
        this.faultOptions = faultTypeDomains;
      });

    });
  },
});
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/initial-variables";

@media only screen and (max-width: $tablet) {
}

@media only screen and (min-width: $tablet) {
  .fault-form {
    position: absolute;
    right: 0px;
    top: 3.25rem;
    width: 25%;
    background: #ffffffdb;
    height: auto;

    .section {
      padding: 1em;
    }
  }
}
input[type="file"] {
  display: none;
}
.file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: whitesmoke;
  &:hover {
    background-color: rgb(237, 237, 237);
  }
}
.selected-file {
  padding: 10px;
}
</style>
