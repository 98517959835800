<template>
    <section class="section">
        <div class="content">
            <h4 class="title is-4">{{ $t('nav.ourServices') }}</h4>
            <div v-html="$t('ourServices')" />
        </div>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import { eventBus } from '../event-bus';

export default Vue.extend({
    name: 'OurServices',
    data: function() {
        return {
            isOpen: false
        };
    },
    watch: {
        isOpen: function(newVal) {
            this.isOpen = newVal;
        }
    },
    created: function() {
        let me = this;
        eventBus.$on('our-services-open', function() {
            me.isOpen = true;
        });
    }
});
</script>
