<template>
    <form action="">
        <div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Log inn</p>
                    <!-- <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/> -->
                </header>
                <section class="modal-card-body">
                    <b-message type="is-danger" v-if="isLoginError">
                        Wrong credentials
                    </b-message>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Brukernavn">
                                <b-input
                                    type="name"
                                    v-model="name"
                                    placeholder=""
                                    required>
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Passord">
                                <b-input
                                    type="password"
                                    v-model="password"
                                    password-reveal
                                    placeholder=""
                                    required>
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot is-justify-content-center">
                    <b-button
                        label="Close"
                        tag="router-link"
                        to="/" />
                    <b-button
                        label="Login"
                        type="is-primary" 
                        @click="login()"/>
                </footer>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import Vue from 'vue';
//import { loginTest } from '../auth.js';
import {login} from '../auth.js';

export default Vue.extend({
    name: 'LoginForm',
    data() {
        return {
            name: '',
            password: '',
            isLoginError: false
        }
    },
    props: {
        armaturPortalName: String
    } ,
    created(){
        console.log(this.armaturPortalName)
        if(this.armaturPortalName === '/sla'){
                this.toast("is-danger", this.$t("slaModeNotAvailable", 7000));
        }
    },

    methods: {
        async login() {
            this.isLoginError = false;
             try {

                await login(this.name, this.password);
                this.$router.push('eierbytte');

/*
            await loginTest();
            this.$router.push('eierbytte');       
*/
            } catch (error) {
                this.isLoginError = true;
            }
        },
    toast: function (type, message, duration) {
      this.$buefy.toast.open({
        duration: duration || 5000,
        message: message,
        position: "is-bottom",
        type: type,
      });
    },
    }
});



</script>

<style lang="scss" scoped>
form {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
</style>