<template>
    <section class="section">
        <h4 class="title is-4">{{ $t('nav.legend') }}</h4>
        <div class="wrapper">
            <div class="columns" v-for="item in legend" :key="item.title">
                <div class="column is-1" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                            <g transform="matrix(1,0,0,1,6,6)">
                                <image :xlink:href="'data:image/png;base64,' + item.imageBase64" x="-6" y="-6" width="12" height="12" preserveAspectRatio="none" />
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="column">
                    <h6 class="title is-6">{{ item.title }}</h6>
                    <span v-if="item.description">{{ item.description }}</span>
                </div>
            </div>
        </div>
        
    </section>
</template>

<script lang="js">
import Vue from 'vue';
import _ from 'lodash';
import { isServiceUser, isSlaUser } from '../utils';

export default Vue.extend({
    name: 'Legend',
    data: function() {
        return {
            normal: this.$t('status.legend.normal')
        }
    },
    computed: {
        /**
         * computed property of Legend mode based on current URL
         * @return {method} - returns method of Legend display
         */
        legend: function() {
            return (isServiceUser()) ? this.getLegendOwners() : 
                                      (isSlaUser()? this.getLegendSLAs():
                                                    this.getLegendNotifications());
        }
    },
    methods: {
        getLegendNotifications() {
            return [
                {
                    imageBase64: 'iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAQ5JREFUKJGNkb9LAnEYh5+73vji0mARkVOLQoMFDYHg0NB6BkGpY0ODOLjaHPQHBP0LV0PQIBjnGBS3SGPuLYHd4KLfO89rsO/Uj/Mzvbw8D7wvH8HkgUxpzHlsUQGVAz2cgqdmXD/XCQwmAGWXrXCiurGlC/O1BsgLlGKb5v6dcvxT/TIX7lFhpDpg4B9ZI9GdgsvOoMa77EWcgd7+AzbJrlpcAA0RcFJgAGI4AhoC5BYRgA162AIMFxQCDpkJ4AEHafTU4hFAVpa5GUU0U06bSMIlgPSOGZVvs05I0AXWf4HHQN2v8gbfxT1Vg/6uS3HJVm1JdAXYBD4BD5sr/4SBscUMrzU+QLeA1n+/fAF0YEx09F6cxQAAAABJRU5ErkJggg==',
                    title: this.$t('status.name.normal'),
                    description: this.$t('status.description.normal')
                },
                {
                    imageBase64: 'iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAPFJREFUKJGNz70uxFEQQPHfrJv8o92ICJWGREGvU2iXROKrVChEoaWWeACJV1gKiUJC1gPo1PQaCVtouMlyFWs1PtappjgnM5N8Us4MyjaxIKoxJT+i5c1hrGv3vASlaVyuLsiT3TrDBGYN2C4nVSNW8jWkcqrSqc6/5O8Mkc9L00ysuU86NshTv8g96qVmD1tJaCh9dASL3aAY66+DkXKllvD4z6Ad894TWpjrZxcuIUmOdGzz52mvUexDiiXP5bjeEO0LDP8gvwjrseK2uwGx2r4pTdOlVu2GvIBRPBVaEQ5i2V2vTr0h1jyQd7Dz1y8fVUxItMDNQWoAAAAASUVORK5CYII=',
                    title: this.$t('status.name.registered'),
                    description: this.$t('status.description.registered')
                },
                {
                    imageBase64: 'iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAANtJREFUKJGN0K1OQ1EQReHvkJOcYBtCCFUYSBDgcbxAi0YiEKSiFnQTHoCEJ2lS0hfAocFjSMoVGBjDQbQXw0+71Yi1MjM7W6SyjnP0ldIVMcMUN4mm5fIC3lHKRMQeiIBdHGFQS+mliHvIlaKU8Tf8MxsixpXDxHPGmYj9P+A2ncoVLjJ6S2CQOGmF7ioCtiprGbMVhSbxmc2rO15GV+6Y13qLwZLTPhIjyIm32un0NM0Em7/A7zhNPLYbpKZ5qBzUUi5TRB/beK1ME9eJp9bO7ZB4ETHE8L9fvgDRO0A6vtnOuwAAAABJRU5ErkJggg==',
                    title: this.$t('status.name.confirmed'),
                    description: this.$t('status.description.confirmed')
                },
                {
                    imageBase64: 'iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAPJJREFUKJGNzr8rRXEYx/HXczp1kk2SmCyUgd3mH7jXbJQMUrgbi0XZ7g1l8mcouv+AzcxuUddNDJzhnq/hdEx+vevZ3u9PT67hKI15sYV2kcyWYYC+5NxZDBstB500V7y4LlmAMsA8VoSdYj+1yl7c1sFmKoqRq0b+hsmycqWTlnXjMTduo2TxB7lhwigdYjuXaan+0EGs1UFl9j86pklZjsE/gyFR5TJ9ldW//XQDuTcXxu3w62sfsjiug8t4neik1nDkGlPfyO8y63pxXwcYduPObloqpIMyRVuYwbMs9UWc6MZDU+dfO6fxVLKnvh/5BDXBSeyiNr/iAAAAAElFTkSuQmCC',
                    title: this.$t('status.name.inProgress'),
                    description: this.$t('status.description.inProgress')
                },
                {
                    imageBase64: 'iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAMtJREFUGJWN0CFLg1EUgOHnjIsLNvuCfVnhm2HFotGswbJuEMRi8g+IwSIrFv+GbmDQKmI1mBaFTz49lglTdPrGcx8Oh1tMG8kdHGF5OqrJyyL2V8UzFBi334+zduBrbWK7oT+WVSWeyrid1Q9wtk5yhs2SdQ6IORZsXMlOIbp/SWjRLUGT/9FtTUl5Q6z8QZus3ZYQJ8kAC7/bPF/TmpRKPI7kLoamX/mt6zex5/OxJy5GS3lv4hB9LOIBwxdO18Wr2U29Sdxha97hH0v2PXXyE0KSAAAAAElFTkSuQmCC',
                    title: this.$t('status.name.planned'),
                    description: this.$t('status.description.planned')
                }
            ]
        },
        getLegendOwners() {
            const p = this.$parent.$parent.map.layers.items.filter(m =>  m.id === "owners-layer")[0].renderer.uniqueValueInfos;

            const o = p.map((item) => ({
                imageBase64: item.symbol.source.imageData,
                title: item.label,
                description: item.description
            }));

            const uniq = _.uniqBy(o, 'title');

            return uniq;
        },
        getLegendSLAs() {
            const p = this.$parent.$parent.map.layers.items.filter(m =>  m.id === "slas-layer")[0].renderer.uniqueValueInfos;
            const o = p.map((item) => ({
                imageBase64: item.symbol.source.imageData,
                title: item.value + " " + item.label,
            }));

            const uniq = _.uniqBy(o, 'title');

            return uniq;
        }
    }
})
</script>

<style scoped lang="scss">
    .wrapper {
        max-height: 600px;
        min-height: 380px;
        overflow-y: auto;
        overflow-x: hidden;

        svg {
            position: relative;
            top: -3px;
        }
    }
    .title.is-6 {
        margin-bottom: 4px;
    }
</style>
