<template>
    <div class="geolocation">
        <b-tooltip :label="$t('nav.showMyLocation')" position="is-bottom" type="is-light">
            <a @click="findMe" class="button">
                <span aria-hidden="true" class="esri-icon esri-icon-locate"></span>
            </a>
        </b-tooltip>
        <b-modal
            :active.sync="showGeolocationDisabled"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <GeolocationDisabled :message="errorMessage" />
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
let projection = require('@arcgis/core/geometry/projection');
let geometryEngine = require('@arcgis/core/geometry/geometryEngine');
// import  geometryEngine  from "@arcgis/core/geometry/geometryEngine";
// import projection from "@arcgis/core/geometry/projection";
import * as _ from 'underscore';
import GeolocationDisabled from '../components/GeolocationDisabled.vue';
import MapView from '@arcgis/core/views/MapView';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import Circle from '@arcgis/core/geometry/Circle';
import { Config } from '@/config';

import { eventBus } from '../event-bus';

export default Vue.extend({
    name: 'Geolocation',
    components: { GeolocationDisabled },
    props: {
        view: {
            type: MapView,
            required: true
        }
    },
    data() {
        return {
            positon: [],
            accuracy: null,
            showGeolocationDisabled: false,
            errorMessage: null
        };
    },

    methods: {
        findMe: function() {
            this.view.graphics.removeAll();
            const loadingComponent = this.$buefy.loading.open();
            navigator.geolocation.getCurrentPosition(
                position => {
                    loadingComponent.close();
                    let tmpAccuracy = position.coords.accuracy;
                    // let tmpAccuracy = 7;

                    this.$buefy.toast.open({
                        message: this.$t('geolocation.locationAccuracy', { accuracy: tmpAccuracy }),
                        duration: 10000
                    });
                    this.drawOnMap(position.coords.latitude, position.coords.longitude, tmpAccuracy);
                    // console.log('GPS position :>> ', position.coords);
                },
                error => {
                    loadingComponent.close();

                    this.showGeolocationDisabled = true;
                    let detailedMsg = error.message ? ` (${error.message})` : '';
                    let errorCodes = {
                        1: this.$t('geolocation.code_1'),
                        2: this.$t('geolocation.code_2'),
                        3: this.$t('geolocation.code_3')
                    };
                    let message = errorCodes[error.code];

                    if(!errorCodes[error.code]) message = 'Unknown error when obtaining position';
                    this.errorMessage = message + detailedMsg;
                    console.error(error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        },

        drawOnMap: function(lat, lon, accuracy) {
            var accuracySymbol = {
                type: 'simple-fill',
                color: [237, 237, 237, 0.2], // orange, opacity 80%
                outline: {
                    color: [255, 255, 255],
                    width: 1
                }
            };

            const size = 24;
            const pinSymbol = {
                type: 'text', // autocasts as new TextSymbol()
                color: 'red',
                text: '\ue630', // esri-icon-map-pin
                haloColor: 'white',
                haloSize: 1,
                yoffset: -size / 2,
                font: {
                    size: size,
                    family: 'CalciteWebCoreIcons'
                }
            };

            // Create a graphic and add the geometry and symbol to it
            var pointGraphic = new Graphic({
                geometry: new Point({
                    latitude: lat,
                    longitude: lon,
                    type: 'point',
                    spatialReference: SpatialReference.WGS84
                }),
                attributes: {
                    ID: '111223',
                    url: 'hello'
                }
            });

           // convert coordinates from wgs84 to 25833
            projection.load().then(() => {
                // the projection module is loaded. Geometries can be re-projected.

                // projects each polygon in the array
                // project() will use the spatial reference of the first geometry in the array
                // as an input spatial reference. It will use the default transformation
                // if one is required when converting from input spatial reference
                // to the output spatial reference
                var outSpatialReference = new SpatialReference({
                    wkid: 25833
                });

                let pointGraphicProjected = projection.project(pointGraphic.geometry, outSpatialReference);
                this.view.goTo({ target: pointGraphicProjected, zoom: Config.ext.mapconfig.maxZoom });

                var accuracyCircle = new Circle({
                    center: pointGraphicProjected,
                    radius: accuracy,
                    radiusUnit: 'meters',
                    spatialReference: { wkid: 25833 }
                });
                this.view.graphics.add(
                    new Graphic({
                        geometry: pointGraphicProjected,
                        attributes: { id: 778887 },
                        symbol: pinSymbol
                    })
                );

                this.view.graphics.add(
                    new Graphic({
                        geometry: accuracyCircle,
                        attributes: { id: 777 },
                        symbol: accuracySymbol
                    })
                );

                let armaturLayer = this.view.map.findLayerById('lamps-layer');
                // wait for the layer view to finish updating
                var query = armaturLayer.createQuery();
                query.geometry = pointGraphicProjected; // the point location of the pointer
                query.distance = 50;
                query.units = 'meters';
                query.spatialRelationship = 'intersects'; // this is the default
                query.returnGeometry = true;
                query.outFields = ['OBJECTID', 'VED_ADRESSE', 'ASSETID'];
                // get all the features available for drawing.
                armaturLayer
                    .queryFeatures(query)
                    .then(function(results) {
                        // do something with the resulting graphics
                        var graphics = results.features;

                        let nearestLamp = _.min(graphics, function(g) {
                            let distance = geometryEngine.distance(pointGraphicProjected, g.geometry);
                            return distance;
                        });
                        if (
                            accuracy <= Config.ext.autoSuggestAccuracyLimit &&
                            geometryEngine.distance(pointGraphicProjected, nearestLamp.geometry) <
                                3 * Config.ext.autoSuggestAccuracyLimit
                        ) {
                            eventBus.$emit('foundNearestLamp', nearestLamp);
                        }
                    })
                    .catch(error => {
                        console.error('layer view query error:', error);
                    });
            });
        }
    }
});
</script>

<style lang="css" scoped>
.geolocation {
    width: 60px;
}
</style>
