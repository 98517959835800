<template>
  <div class="custom-search"></div>
</template>

<script>
import MapView from "@arcgis/core/views/MapView";
import axios from "axios";
import Point from "@arcgis/core/geometry/Point";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Graphic from "@arcgis/core/Graphic";
import Search from "@arcgis/core/widgets/Search";
import SearchSource from "@arcgis/core/widgets/Search/SearchSource";
import proj4 from "proj4";
import { Config } from "../config";
import { isServiceUser, isSlaUser } from '../utils';

export default {
  name: "CustomSearch",
  props: {
    view: {
      type: MapView,
      requFired: true,
    },
  },
  data: function () {
    return {
      searchSource: null, //SearchSource,
      searchWidget: null, //Search;
      selectGraphic: null, //Graphic
    };
  },

  methods: {
/**
 * method to receive the ArmaturLayer name from the current Portal State, based on the actual URL
 * @return {String} string name of the Armatur layer on the map
 */
    getArmaturLayerName(){
      let armaturLayerName = '';
      (isServiceUser())? armaturLayerName =  "owners-layer" : 
                        isSlaUser()? armaturLayerName = "slas-layer":
                                    armaturLayerName = "lamps-layer";
      return armaturLayerName;
    },
    
    createQueryForObjectId(objectId) {
      /*INITIAL IMPLEMENTATION
      const armaturLayer = (isServiceUser()) 
                                ? this.view.map.findLayerById('owners-layer')
                                : this.view.map.findLayerById('lamps-layer');*/

      const armaturLayer = this.view.map.findLayerById(this.getArmaturLayerName());                          
                   
      const srcWkt = "+proj=utm +zone=32 +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
      const destWkt = "+proj=longlat +ellps=GRS80 +no_defs";
      const  query = armaturLayer.createQuery();

      query.where = `OBJECTID=${objectId}`;

      return armaturLayer
                  .queryFeatures(query)
                  .then((response) => {
                      if (!response.features.length) {
                        return { status: 400 }
                      }
                      const feature = response.features[0];
                      const p = proj4(srcWkt, destWkt, [feature.geometry.x, feature.geometry.y])

                      const pp = {
                        status: 200,
                        data: {
                          adresser: [
                            {
                              representasjonspunkt: {
                              lat: p[1],
                              lon: p[0],
                              epsg: 'EPSG:4258'
                            },
                            adressetekst: `[${objectId}] - ${feature.attributes.VED_ADRESSE || ''}`,
                            adresser: [objectId],
                            poststed: ''
                            }
                          ]
                        }
                      }
                      return pp;
                    })
                    .catch(() => ({ status: 400 }));
      
    },
    initEvents() {
      this.searchWidget.on("select-result", (event) => {
        const point = event.result.feature.geometry;
        console.log('point', point);
        point.spatialReference = new SpatialReference({
          wkid: Config.ext.mapconfig.baseLayer.wkid,
        });

        const simpleMarkerSymbol = {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          style: "circle",
          color: "blue",
          size: "15px", // pixels
          outline: {
            // autocasts as new SimpleLineSymbol()
            color: "white",
            width: 3, // points
          },
        };

        this.selectGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
        });
        this.view.graphics.add(this.selectGraphic);
      });

      this.searchWidget.on("search-clear", () => {
        this.view.graphics.remove(this.selectGraphic);
      });
    },

    searchSuggestions(params) {
      return new Promise((resolve, reject) => {
        //IPromise<__esri.SuggestResult[]>
        const requests = []; //Array<Promise<AxiosResponse<any>>>
        const term = params.suggestTerm.replace(/ /g, "+");
        const url = Config.ext.search.baseUrl;
        const treffPerSide = Config.ext.search.treffPerSide;
        const side = Config.ext.search.side;
        const appendToUrl = Config.ext.search.appendToUrl;
        Config.ext.search.kommunesToSearch.forEach((kommuneNum) => {
          requests.push(
            axios.get(
              `${url}?sok=${term}*&kommunenummer=${kommuneNum}&treffPerSide=${treffPerSide}&side=${side}${appendToUrl}`
            )
          );
        });

        requests.push(this.createQueryForObjectId(term))

        axios
          .all(requests)
          .then((results) => {
            let responses = [];
            results.forEach((element) => {
              if (element.status === 200) {
                if (element.data.adresser.length > 0) {
                  responses = responses.concat(element.data.adresser);
                }
              }
            });



            const suggestedResults = responses.map((res) => {
              // res: Adresser
              return {
                key: JSON.stringify(res.representasjonspunkt),
                text: res.adressetekst + ", " + res.poststed,
                sourceIndex: params.sourceIndex,
              };
            });

            console.log('suggestedResults', suggestedResults)
            // sort all results
            const suggestedResultsSorted = suggestedResults.sort((a, b) =>
              a.text.localeCompare(b.text, "no", {
                numeric: true,
                sensitivity: "base",
                ignorePunctuation: true,
              })
            );

            console.log(suggestedResultsSorted);

            resolve(suggestedResultsSorted);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    searchResults(resultParam) {
      //IPromise<__esri.SearchResult[]>
      return new Promise((resolve) => {
        //<__esri.SearchResult[]>
        // reproject coordinates
        const suggestPoint = JSON.parse(resultParam.suggestResult.key);
        const srcWkt = "+proj=longlat +ellps=GRS80 +no_defs";
        const destWkt =
          "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
        const projectedPoint = proj4(srcWkt, destWkt, [
          suggestPoint.lon,
          suggestPoint.lat,
        ]);

        const graphicResult = new Graphic({
          geometry: new Point({
            x: projectedPoint[0],
            y: projectedPoint[1],
            spatialReference: new SpatialReference({
              wkid: Config.ext.mapconfig.baseLayer.wkid,
            }),
          }),
        });
        const searchResult = {
          // __esri.SearchResult
          extent: null,
          feature: graphicResult,
          name: resultParam.suggestResult.text,
        };

        this.view
          .goTo({
            center: graphicResult,
          })
          .catch(function (error) {
            if (error.name != "AbortError") {
              console.error(error);
            }
          });
        resolve([searchResult]);
      });
    },
  },
  async mounted() {
    this.view
      .when(() => {
        // this.view = view;
        this.searchSource = new SearchSource({
          getResults: this.searchResults,
          getSuggestions: this.searchSuggestions,
          placeholder: this.$t("search.placeholder"),
        });

        this.searchWidget = new Search({
          container: this.$el,
          view: this.view,
          sources: [this.searchSource],
          includeDefaultSources: false,
          minSuggestCharacters: 2,
          popupEnabled: true,
          locationEnabled: false,
        });

        this.initEvents();

        this.createQueryForObjectId();
      })
      .catch((err) => {
        console.error("Error loading custom search widget.", err);
      });
  },
};
</script>
<style lang="css" scoped>
div {
  width: 200px;
}
</style>
