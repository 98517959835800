<template>
    <router-view />
</template>

<script lang="js">

export default {
    name: 'Wrapper',
    mounted() {
        console.log("Mounted Wrapper!");
    }
}
</script>

<style lang="scss" scoped>

</style>
