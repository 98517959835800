import './css/main.scss';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import * as urlUtils from '@arcgis/core/core/urlUtils';
import Vue from 'vue';
import VueSmoothScroll from 'vue2-smooth-scroll';

import Wrapper from './Wrapper.vue';
import { Config } from './config';
import i18n from './i18n';
import router from './router';


const axios = require('axios').default;

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

//Vue.use(Buefy, { defaultIconComponent: FontAwesomeIcon });

Vue.use(Buefy, {
    defaultIconComponent: FontAwesomeIcon,
    defaultIconPack: "fas",
    customIconPacks: {
      fas: {
        sizes: {
          default: "lg",
          "is-small": "1x",
          "is-medium": "2x",
          "is-large": "3x"
        }
      }
    }
  });

Vue.use(VueSmoothScroll, {
    duration: 400,
    updateHistory: false,
});

axios
    .get('appconfig.json')
    .then(response => {
        Config.ext = response.data;
        if (Config.ext.esriProxy) {
            urlUtils.addProxyRule({
                urlPrefix: Config.ext.esriProxy.urlPrefix,
                proxyUrl: Config.ext.esriProxy.proxyUrl
            });
        }
        
        new Vue({
            i18n,
            router: router(),

            render() {
                return <Wrapper />;
            },
            mounted() {
                console.log("Mounted Main!");
            }
        }).$mount('#app')
    })
    .catch(error => {
        console.error(error);
    });

// new Vue({
//   i18n,
//   render: h => h(App)
// }).$mount('#app')


