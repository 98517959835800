<template>
    <div class="gatelys-map" ref="gatelys-map">
        <slot :map="map" :scale="scale" :time="time" :view="view">
            <PopupHolder :scale="scale" :view="view" v-show="false"></PopupHolder>
        </slot>
    </div>
</template>

<script lang="js">
import MapView from '@arcgis/core/views/MapView';
import PopupHolder from '../components/PopupHolder.vue';

export default {
    name: 'WebMap',
    components: { PopupHolder},

    props: {
        config: {},
        view: {
            type: MapView,
            required: true
        }
    },

    data() {
        return {
            selectedFeature: {},
            map: {},
            time: new Date(),
            scale: -1,

        };
    },
    computed: {
        zoom: function(){
            return this.scale;
        }
    },
    methods: {
        setLocation(loc){
            this.popupLocation = loc;
        },
        isTouch(){
            return window.innerWidth < 769; //TODO get breakpoint variable form Bulma (Buefy) sass
        }
    },
    mounted() {
        this.view.container = this.$el;
    },
    created() {
       //DEBUG to get zoom level
        // this.view.watch('scale', (newVal)=> {
        //     this.scale = newVal;
        //     console.log('this.view.zoom :>> ', this.view.zoom);
        // });
        // console.log('WebMap view: ', this.view);
    }
};
</script>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/initial-variables';

div .gatelys-map {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

</style>
