<template>
    <section class="section">
        <div class="content">
            <h4 class="title is-4">{{ $t('nav.aboutLyse') }}</h4>

            <div v-html="$t('about')" />
        </div>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
    name: 'About'
});
</script>
