
export const MELDINGSTYPE = {
    DANGER: 'Fare for liv og helse', //1
    AREA_DARKENED: 'Et område er mørklagt', //2
    ONE_SPOT_NOT_BRIGHTNING: 'Ett lyspunkt lyser ikke som det skal', //3
    OTHER: 'Annet (fritekst)', //4
}

let meldingstypeTranslations = new Map();
meldingstypeTranslations.set(MELDINGSTYPE.DANGER, 'faultType.name.danger');
meldingstypeTranslations.set(MELDINGSTYPE.AREA_DARKENED, 'faultType.name.darkArea');
meldingstypeTranslations.set(MELDINGSTYPE.ONE_SPOT_NOT_BRIGHTNING, 'faultType.name.notBright');
meldingstypeTranslations.set(MELDINGSTYPE.OTHER, 'faultType.name.other');

export const MELDINGSTYPE_TRANS = meldingstypeTranslations;
// {
//     "name": "MELDINGSTYPE",
//     "type": "esriFieldTypeString",
//     "alias": "Meldingstype",
//     "domain": {
//      "type": "codedValue",
//      "name": "Lyse_GatelysMelding_3",
//      "description": "Lyse gatelys meldingstype",
//      "codedValues": [
//       {
    //DANGER
//        "name": "Fare for liv og helse",
//        "code": "Fare for liv og helse"
//       },
//       {
    // AREA DARKENED
//        "name": "Et område er mørklagt",
//        "code": "Et område er mørklagt"
//       },
//       {
    // one spot not correct
//        "name": "Ett lyspunkt lyser ikke som det skal",
//        "code": "Ett lyspunkt lyser ikke som det skal"
//       },
//       {
    // other
//        "name": "Annet (fritekst)",
//        "code": "Annet (fritekst)"
//       }
//      ],
//      "mergePolicy": "esriMPTDefaultValue",
//      "splitPolicy": "esriSPTDefaultValue"
//     },
//     "editable": true,
//     "nullable": true,
//     "length": 50
//    },